import { Outlet, Link, useLocation } from "react-router-dom";
import '../assets/css/components/BottomMenu.css';


const BottomMenu = () => {

  const path = useLocation() ;

  return (
    <>
      <nav className="bottom-menu-container">
        <ul className="bottom-menu">
          <li>
            <Link className="bottom-menu-link" to="/">
              {path.pathname.split("/")[1] === "leads" ?
              <i className="ph-fill ph-users-three"></i> :
              <i className="ph-light ph-users-three"></i>}
            </Link>
          </li>
          <li>
            <Link className="bottom-menu-link" to="/agenda">
              {path.pathname === "/agenda" ?
              <i className="ph-fill ph-calendar-blank"></i>:
              <i className="ph-light ph-calendar-blank"></i>}
            </Link>
          </li>
          <li>
            <Link className="bottom-menu-link" to="/contacts">
              {path.pathname === "/contacts" ?
              <i className="ph-fill ph-currency-circle-dollar"></i>:
              <i className="ph-light ph-currency-circle-dollar"></i>}
            </Link>
          </li>
          <li>
          <Link className="bottom-menu-link" to="/settings">
              {path.pathname === "/settings" ?
              <i className="ph-fill ph-gear"></i>:
              <i className="ph-light ph-gear"></i>}
            </Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default BottomMenu;
