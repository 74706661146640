import { useLocation } from "react-router-dom";

import List from "../components/List";
import { getList, isAuth } from "../services/Api";
import { useState, useEffect, useCallback } from "react";
import { Spinner } from "react-activity";

import "../assets/css/pages/Contacts.css";
import "react-activity/dist/library.css";

import LeadCard from '../components/LeadCard';
import I18n from "i18n-js";


const Contacts = (props) => {
  const [leadList, setLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  const location = useLocation();
  const path = location.pathname.split("/")
  const module_lower = path.pop()

  const module = module_lower.charAt(0).toUpperCase() + module_lower.slice(1);

  const { pageSize } = props;

  // Metodo para crear la lista de elementos haciendo el request al CRM
  const createLeadList = useCallback (async () => {

    if (loading) return
    setLoading(true)

    // si esta autenticado y aun quedan paginas
    if (await isAuth() && nextPage !== 0){

      // hago el request
      await getList(
        module?module:"Contacts",
        pageSize,
        nextPage,
        null,
        null,
        null,
        false,//module!="Users",
        null, "-date_entered"
        )

      // si el request es exitoso
      .then((req) => {

        // agrego los nuevos elementos a la lista
        setLeadList([...leadList, ...req.data])

        // se pasa a la proxima pagina
        setNextPage(nextPage+1)

        // Si la data esta vacia y no hay links a la proxima pagina (es decir la anterior fue la ultima pagina y esta esta vacia)
        if (req.data.length === 0 || !Object.keys(req).includes("links")){
          // la proxima pagina es 0, asi indico que no hay mas
          setNextPage(0)
        }
      }).catch()
    }
    // indico que no se esta cargando
    setLoading(false)

  },[module,pageSize,nextPage,leadList,loading]);

  useEffect(() => {
    createLeadList()
  }, [createLeadList])


  if (leadList.length === 0){
    return (
      (!loading&&<p className="emptyList">{I18n.t("contactsEmptyList")}</p>)||
      <Spinner className="spinner" size={32} speed={1} animating={loading} />
    )
  }

  return (
    <div className="contacts-list-container">

      <List
        data={leadList}
        keyExtractor={(item) => item.id}
        itemMapper={(item)=> <LeadCard lead={item} navigateTo={"/contact/"+item.id}/>}
      />

    </div>
  );
};

export default Contacts;
