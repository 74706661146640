import { Link } from "react-router-dom";
import {Fragment} from "react";
import '../assets/css/components/LeadCard.css';


const calcDate = (date) => {

  const dateObj = new Date(date);
  const now = new Date();
  if (now-date > 86400000){
    return dateObj.getUTCFullYear()+"/"+dateObj.getUTCMonth()+"/"+dateObj.getUTCDate()+" "+dateObj.toLocaleTimeString();
  }
  return dateObj.toLocaleTimeString();

}

const LeadCard = (props) => {

  const { navigateTo } = props;
  const { name, lead_id, date_entered } = "attributes" in props.lead ? props.lead.attributes : props.lead;

  return (
    <div className="lead-card">
      <Link className="lead-card-items" to={navigateTo}>
          <div className="name-and-logo">
            <i className="ph-fill ph-user-circle"/>
            <div className="list-text"><Fragment>{name}</Fragment></div>
          </div>
          <div className="list-text">
            <Fragment>{lead_id}</Fragment>
          </div>
          <div className="list-text">
            <Fragment>{calcDate(date_entered)}</Fragment>
          </div>
      </Link>
    </div>
  )
};

export default LeadCard;
