import { Link } from "react-router-dom";

import '../../assets/css/components/detail_view/AddTaskButton.css';


const AddTaskButton = (props) => {
  const {label, id} = props;
  return (
    <div className="add-task-container">
        <p className='add-task-label'> {label} </p>
        <Link className='add-task-link' to={"/task?id="+id}>+</Link>
    </div>
  )

}

export default AddTaskButton;
