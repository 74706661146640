import { useForm } from "../hooks/useForm";
import { useState,useEffect } from 'react'

import { auth, isAuth } from "../services/Api";

import '../assets/css/LoginScreen.css';

import { Spinner } from "react-activity";
import GLOBAL from '../services/global.js'
import i18n from "i18n-js"


const initialForm = {
  username: !!sessionStorage.getItem("username")?sessionStorage.getItem("username"):"",
  password: !!sessionStorage.getItem("password")?sessionStorage.getItem("password"):"",
  url: !!sessionStorage.getItem("url")?sessionStorage.getItem("url").split(".")[1]:"",
};

const LoginScreen = () => {
  const [loginFalied, setLoginFalied] = useState(false);
  const [showCRMImg, setShowCRMImg] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [errorField, setErrorField] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const CRMLogoPath = '/custom/themes/default/images/company_logo.png'

  useEffect(() => {

  }, [])

  const onPressLogIn = async ()=>{
    setShowLoading(true)
    const status = await auth(
      formValues.username,
      formValues.password,
      `https://crm.${formValues.url}.masterdealer.co`
      )

    if (status === 200){
      setShowLoading(false)
    }else if(status === 0){
      setLoginFalied(true)
      setShowLoading(false)
      setErrorField(i18n.t('loginUrlExample'))
      setErrorMessage(i18n.t("loginErrorMessageCompany"))
    }else{
      setLoginFalied(true)
      setShowLoading(false)
      setErrorField(`${i18n.t("loginUsername")} ${i18n.t("loginErrorMessageOr")} ${i18n.t("loginPassword")}`)
      setErrorMessage(i18n.t("loginErrorMessageCredentials"))
    }

    await isAuth()
      .then((r) => GLOBAL.app[1](r))
      .catch(() => GLOBAL.app[1](false))
    setLoginFalied(!GLOBAL.app[0])
  }

  return (
    <div className="login-container">
      <div className="crm-img-div">{
        <img
          className={showCRMImg? "crm-img-class":"crm-img-class-not"}
          onLoad={()=>setShowCRMImg(true)}
          onError={()=>{setShowCRMImg(false)}}
          src={`https://crm.${formValues.url}.masterdealer.co` + CRMLogoPath}
        />
      }
      </div>
      <div className="login-form-div">

        <div className="inputs-div">

          <input
            name="url"
            placeholder={i18n.t('loginUrlExample')}
            value={formValues.url}
            onChange={(a) => {
              handleInputChange(a)
            }}
            />

          <input
            name="username"
            placeholder={i18n.t('loginUsername')}
            value={formValues.username}
            onChange={handleInputChange}
            />

          <input
            name="password"
            placeholder={i18n.t('loginPassword')}
            value={formValues.password}
            onChange={handleInputChange}
            onSubmit={onPressLogIn}
            type="password"
            />

          {loginFalied && <div className="login-error-message"><p>{i18n.t('loginErrorField')} {errorField}</p><p>{errorMessage}</p></div>}
          {showLoading?<Spinner className="spinner-white" size={32} speed={1} animating={showLoading} />:""}
        </div>

        <div className="button-div">
          {/*!this.state.loginFalied && this.state.showLoading &&
            <ActivityIndicator color={Theme.COLORS.WHITE} size="large" />
          */}
          <button
            onClick={() => onPressLogIn()}
            >

              {i18n.t('loginButton')}

          </button>

        </div>
      </div>
    </div>
  );

};
  
  export default LoginScreen;
  