
import '../../assets/css/components/detail_view/Input.css';


const Input = (props) => {

  const { label, tall, className, inputclassname } = props;

  const name = "input-container" + (className?" "+className:"");
  //const inputName = "input-component" + (inputClassName?" "+inputClassName:"");
  const inputName = inputclassname?inputclassname:"input-component";

  if (!tall){

    return (
    <span className={name}>
        {!!label&&<p className='input-label'> {label} </p>}
        <input
            {...props}
            className={inputName}
            />
    </span>
  )
  }
  return (
    <span className={name}>
        {!!label&&<p className='input-label'> {label} </p>}
        <textarea
            {...props}
            className={inputName}
        />
    </span>
  )
};

export default Input;
