import './assets/css/App.css';
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";

import BottomMenu from "./components/BottomMenu";
import Header from "./components/Header";
import TopBar from "./components/TopBar";

import Leads from "./pages/Leads";
import Tasks from "./pages/Tasks";
import Contact from "./pages/Contacts";
import Settings from "./pages/Settings";
import LoginScreen from "./pages/LoginScreen";
import OneLead from "./pages/OneLead";
import CreateLead from "./pages/CreateLead";
import OneContact from "./pages/OneContact";
import Search from "./pages/Search.js";
//import NoPage from "./pages/NoPage";

import i18n from "i18n-js";
import GLOBAL from './services/global.js'
import GlobalStore from './components/GlobalStore';

import { isAuth } from './services/Api.js';
import { useEffect, useState } from "react";
import CreateTask from './pages/CreateTask.js';

import {en, es, ptBR} from './lang/translate';


function App() {

  const [isAuthVal, setIsAuthVal] = useState(false)

  GLOBAL.app = [isAuthVal, setIsAuthVal];
  GLOBAL.screen1 = new GlobalStore();

  useEffect(() => {
    isAuth()
    .then((r) => GLOBAL.app[1](r))
    .catch(() => GLOBAL.app[1](false))
  }, [isAuthVal])

  const [allowedStates, setAllowedStates] = useState(["Disponible"])

  useEffect(() => {
    GLOBAL.screen1.loadConfig('Flujo').then((r) =>
        {
          if (!!r) setAllowedStates(r)
        }
    )
  }, []);

  useEffect(() => {

    i18n.fallbacks = true;
    i18n.translations = {en, es, "pt-BR":ptBR};


    let language = sessionStorage.getItem("lang");

    if (!!language){
      i18n.locale = language
    }else{
      i18n.locale = "es"
    }

  }, [])

  if (!isAuthVal){
    return (
      <HashRouter>
        <div className="main-app-container">
        <Routes>
          <Route path="*" element={<Navigate to="login" replace />} />
          <Route path="/login" element={<LoginScreen />} />
        </Routes>
        </div>
      </HashRouter>
    )
  }

  return (
    <HashRouter>
      <div className="main-app-container">
      <Routes>
        <Route path="/" element={<Navigate to={"leads/"+allowedStates[0]} replace />} />

        <Route className="main-header" element={<Header />}>
          <Route className="main-bottom-menu" element={<BottomMenu />}>

            <Route element={<TopBar />}>
              <Route path="/leads/*" element={<Leads key={new Date()} />}/>
            </Route>

            <Route path="/agenda" element={<Tasks />} />
            <Route path="/contacts" element={<Contact />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/" replace />} />

            <Route path="/search/*" element={<Search />} />
          </Route>

          <Route>
            <Route path="/lead/*" element={<OneLead />} />
            <Route path="/createLead*" element={<CreateLead />} />
            <Route path="/contact/*" element={<OneContact />} />
            <Route path="/task*" element={<CreateTask />} />
          </Route>

        </Route>
      </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
