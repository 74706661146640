import List from "../components/List";
import { getList, isAuth } from "../services/Api";
import { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import { Spinner } from "react-activity";
import LeadCard from '../components/LeadCard';
import i18n  from "i18n-js";

import "../assets/css/pages/Leads.css";
import "react-activity/dist/library.css";


const Leads = (props) => {
  const [leadList, setLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  
  const location = useLocation();
  const [clientStatus, setClientStatus] = useState(location.pathname.split("/").pop());

  const path = location.pathname.split("/")
  const path_status = path.pop()
  const module_lower = path.pop()

  const module = module_lower.charAt(0).toUpperCase() + module_lower.slice(1);

  //const { pageSize } = props;

  useEffect(() => {
    setClientStatus(path_status)
    setLeadList([])
  }, [path_status])

  const handleScroll = (event) => { 
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if(bottom){
      loadNextPage();
    }
  }

  // Metodo para crear la lista de elementos haciendo el request al CRM
  const createLeadList = useCallback (async (next_page) => {

    setLoading(true)

    let l = [];
    // si esta autenticado y aun quedan paginas
    if (await isAuth() && next_page !== 0){

      // hago el request
      l = await getList(
        module?module:"Leads",
        20,//pageSize,
        next_page,
        clientStatus,
        null,
        null,
        true,//module!=="Users",
        null,
        "-date_entered"
        )

      // si el request es exitoso
      .then((req) => {

        // agrego los nuevos elementos a la lista
        //setLeadList([...leadList, ...req.data])

        // se pasa a la proxima pagina
        setNextPage(next_page+1)

        // Si la data esta vacia y no hay links a la proxima pagina (es decir la anterior fue la ultima pagina y esta esta vacia)
        if (!Object.keys(req).includes("links")){
          // la proxima pagina es 0, asi indico que no hay mas
          //console.log("no more pages to load")
          setNextPage(0)
        }//*/
        return req.data
      }).catch();
    }
    // indico que no se esta cargando
    setLoading(false)
    return l;

  },[module,clientStatus]);

  useEffect(() => {
    createLeadList(1).then((l)=>
      setLeadList(l)
    );
  }, [createLeadList])

  const loadNextPage=()=>{
    if (loading) return
    createLeadList(nextPage).then((l)=>
      setLeadList([...leadList, ...l])
    );
  }

  const emptyComponent = ()=>{
    return (
      (!loading&&<p className="emptyList"> {i18n.t('leadsEmptyList')+" \""+clientStatus+"\""} </p>)||
      <div className="emptyComponent"><Spinner className="spinner" size={32} speed={1} animating={loading} /></div>
    )
  }

  return (
    <div className="lead-list-container" onScroll={handleScroll}>
      {leadList.length === 0?emptyComponent():
      <List
        key={clientStatus}
        data={leadList}
        keyExtractor={(item) => item.id}
        itemMapper={(item)=> <LeadCard key={item.id} lead={item} navigateTo={"/lead/"+item.id+"/detail"}/>}
      />}
      <div>
        {nextPage!==0&&leadList.length !== 0&&<Spinner className="spinner" size={32} speed={1} animating={loading} />}
      </div>
      <Link className="create-lead-floating-button" to="/createLead">
        <i className="ph-fill ph-user-circle-plus"/>
      </Link>

    </div>
  );
};

export default Leads;
