import { useForm } from "../hooks/useForm";
import { Link, useLocation } from "react-router-dom";
import { getOneitem, patchItem } from "../services/Api";
import { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import GLOBAL from '../services/global.js'

import Title from "../components/detail_view/Title.js"
import Separator from "../components/detail_view/Separator.js"
import Input from "../components/detail_view/Input.js"
import Select from "../components/detail_view/Select.js"
import AddTaskButton from "../components/detail_view/AddTaskButton.js"
import Historial from "../components/Historial.js"

import i18n from "i18n-js"

import "../assets/css/pages/OneLead.css";
import "react-activity/dist/library.css";


const OneLead = () => {

  const [lead, setLead] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusObject, setStatusObject] = useState({});
  const [planList, setPlanList] = useState({});

  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [config, setConfig] = useState({});
  const [formValues, handleInputChange, reset, setForm] = useForm({});

  var location = useLocation().pathname.split("/")
  const top_selected = location.pop()
  const id = location.pop()

  const labelMaker = (x, y) => {
    //x = x == '' ? "None" : x
    return {label:x, value:y}
  }
  // crea las opciones para el dropdown de estado
  const createStatusOptions = async () => {

    // busca los estados en memoria
    let statusList = GLOBAL.screen1.state.statusList
    if (!statusList){
      statusList = await GLOBAL.screen1.getPossibleStatuses()
    }

    await GLOBAL.screen1.loadConfig('Dropdown');
    let drop_down_options = GLOBAL.screen1.state.config_Dropdown;

    let options = statusList.filter(item => drop_down_options.includes(item));

    // crea el diccionario necesario para usar los estados como opciones del dropdown
    let optionsDictList = options.map(option => labelMaker(GLOBAL.screen1.state.states[option]["label"], option))

    // hace un setState para actualizar la lista de opciones del estado
    setStatusOptions(optionsDictList)
  }

  const createDropdownOptions = async (listName) => {

    if (!GLOBAL.screen1.state[listName]){
      await GLOBAL.screen1.createList(listName)
    }

    let optionsDictList = []

    if (!("" in Object.values(GLOBAL.screen1.state[listName]))){
      optionsDictList.push(labelMaker("", ""))
    }
    for (var key in GLOBAL.screen1.state[listName]){
      optionsDictList.push(labelMaker(GLOBAL.screen1.state[listName][key], key))
    }
  
    // hace un setState para actualizar la lista de opciones del estado
    //this.setState({...this.state, statusOptions:optionsDictList})
    return optionsDictList
  }

  const load = async () => {
    setLoading(true)
    await GLOBAL.screen1.loadConfig('whatsapp_url');
    createStatusOptions();
    GLOBAL.screen1.loadStatuses().then((r) => setStatusObject(r));
    await createDropdownOptions('plan_list').then((r) => setPlanList(r))
    await GLOBAL.screen1.loadConfig('Leads').then((conf) => setConfig(conf));
    await getOneitem("Leads", id)
    .then(res => {
      var temp_lead = res
      setLead("attributes" in res ? temp_lead.attributes : temp_lead)
      setForm("attributes" in res ? temp_lead.attributes : temp_lead)

    }).catch((err) => {
      //utils.simpleErrorPopup(err, navigation);
    })
    setLoading(false)

  }

  useEffect(() => {
    load()
  }, [])
  useEffect(() => {
    setForm({...formValues, [config.status]:lead[config.status]})
  }, [statusOptions, lead])

  const renderButtons = () => {
    return (
      <div className="one-lead-buttons-div">
        <button
          className="one-lead-button one-lead-button-cancel"
          onClick={() => {setIsEditing(false); setForm(lead);}}
          >

          {i18n.t('leadsFormCancelBotton')}

        </button>
        <button
          className="one-lead-button one-lead-button-accept"
          onClick={() => {patchItem("Lead", formValues, id).then(()=>setIsEditing(false))}}
          >

          {i18n.t('leadsFormSaveBotton')}

        </button>

      </div>

    )
  }

  if (statusObject[formValues[config.status]]){
    if (statusObject[formValues[config.status]]["substatus"].length===0){
      formValues[config.subestado] = '';
    }
    else if (!statusObject[formValues[config.status]]["substatus"].map((i)=>i["value"]).includes(formValues[config.subestado])) formValues[config.subestado] = statusObject[formValues[config.status]]["substatus"][0]["value"]
  }

  if (!config || loading) return <Spinner className="spinner" size={32} speed={1} animating={loading}/>
  return (

    <>
      <nav className="one-lead-top-menu-container">
        <ul className="top-menu">       
          <li>
            <Link className={top_selected === "detail" ? "top-menu-link-selected":"top-menu-link"} to={"/lead/"+id+"/detail"}>
                <h1>{i18n.t("leadsGeneralTitle")}</h1>
            </Link>
          </li>
          <li>
            <Link className={top_selected === "history" ? "top-menu-link-selected":"top-menu-link"} to={"/lead/"+id+"/history"}>
                <h1>{i18n.t("leadsHistoryTitle")}</h1>
            </Link>
          </li>
        </ul>
      </nav>

    {top_selected === "detail" &&
    <div className="one-lead-container">

        <div className="one-lead-title">
            <Title
              name={config.primer_nombre}
              status={lead.status}
              value={formValues[config.primer_nombre]}
              onChange={handleInputChange}
              onFocus={()=>setIsEditing(true)}
              />
              <div className="one-lead-call-buttons-container">
                {!!GLOBAL.screen1.state.config_whatsapp_url&&
                <Link className="one-lead-call-button" to={GLOBAL.screen1.state.config_whatsapp_url+formValues[config.telefono1]}>
                <i id="one-lead-wpp-icon" className="ph-fill ph-whatsapp-logo"></i>
                </Link>}
                <Link className="one-lead-call-button" to={`tel:${formValues[config.telefono1]}`}>
                <i id="one-lead-call-icon" className="ph-fill ph-phone-outgoing"></i>
                </Link>
              </div>
        </div>

        <Separator/>

        <div className="one-lead-inputs">

            <div className="row">
            {("telefono1" in config) && <div className="col">
                <Input
                  type="number"
                  label={i18n.t('leadsFormPhone1')}
                  name={config.telefono1}
                  value={formValues[config.telefono1]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            {("telefono2" in config) && <div className="col">
                <Input
                  type="number"
                  label={i18n.t('leadsFormPhone2')}
                  name={config.telefono2}
                  value={formValues[config.telefono2]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            </div>

            <div className="row">
            <div className="col">
                {("email" in config) && <div className="col">
                <Input
                  type="email"
                  label={i18n.t('leadsFormEmail')}
                  name={config.email}
                  value={formValues[config.email]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            </div>
            </div>


            <div className="row">
            {("status" in config) && <div className="col">
              <Select
                  data={statusOptions}
                  label={i18n.t('leadsFormStatus')}
                  name={config.status}
                  value={formValues[config.status]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            {("subestado" in config) && <div className="col">
                <Select
                  data={statusObject[formValues[config.status]]["substatus"]}
                  label={i18n.t('leadsFormSubstatus')}
                  name={config.subestado}
                  value={formValues[config.subestado]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            </div>

            <div className="row">
            <div className="col">
                <AddTaskButton label={i18n.t("agendaFormTask")} id={id}/>
            </div>
            {("producto" in config) && <div className="col">

            <Select
                  data={planList}
                  label={i18n.t('leadsFormProduct')}
                  name={config.producto}
                  value={formValues[config.producto]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />

              </div>}
            </div>

            <div className="row">
            <div className="col">
                {("descripcion" in config) && 
                <Input
                  label={i18n.t('leadsFormDescription')}
                  name={config.descripcion}
                  value={formValues[config.descripcion]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                  tall={+true}
                />
              }
            </div>
            </div>

            <div className="row">
            {("direccion" in config) && <div className="col">
              <Input
                  label={i18n.t('leadsFormHomeAddress')}
                  name={config.direccion}
                  value={formValues[config.direccion]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
              }
            {("provincia" in config) && <div className="col">
                <Input
                  label={i18n.t('leadsFormHomeAddress')}
                  name={config.provincia}
                  value={formValues[config.provincia]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
                }
            </div>

            <div className="row">
            {("ciudad" in config) && <div className="col">
                <Input
                  label={i18n.t('leadsFormCity')}
                  name={config.ciudad}
                  value={formValues[config.ciudad]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
            </div>}
            </div>

            <div className="row">
            <div className="col">
                {isEditing && renderButtons()}
            </div>
            </div>

            <br/><br/><br/><br/><br/><br/>

        </div>

    </div>
    }
    {top_selected === "history" &&
      <div>
        <Historial id={id}/>
      </div>
    }

    </>

  );

};

export default OneLead;
