import { Outlet, useLocation } from "react-router-dom";
import '../assets/css/components/Header.css';
import i18n  from "i18n-js";
import { useNavigate } from 'react-router-dom';
import Input from "../components/detail_view/Input.js"


const Header = () => {

  var path = useLocation().pathname.split("/") ;
  var pathName = path[1];
  pathName = pathName.replace('/','');
  var titleTranslate = pathName+"Title";

  const navigation = useNavigate();

  const MAIN_HEADER_TITLES = ["leads", "agenda", "contacts", "settings"];

  const isMain = MAIN_HEADER_TITLES.includes(pathName);
  const showSearch = pathName==="leads"||pathName==="contacts";
  const capitalizedPathName = pathName.charAt(0).toUpperCase() + pathName.slice(1);

  if (pathName==="search") return (<><Outlet /></>);

  return (
    <>
        <div className="header-container">
            {!isMain&&
              <button className="back-button" onClick={()=>navigation(pathName==="lead"?"/":-1)}>{"<"}</button>
            }
            <h1>{i18n.t(titleTranslate)}</h1>
            {showSearch&&
              <Input
                className="header-search-input"
                inputclassname="header-search-input-component"
                placeholder={i18n.t('searchInput'+ capitalizedPathName)}
                onFocus={()=>navigation(pathName!=="search"?"/search/"+pathName:null)}
                />
            }
        </div>
        <Outlet />
    </>
  )
};

export default Header;
