import { useLocation } from "react-router-dom";

import { useForm } from "../hooks/useForm.js";
import { getOneitem, patchItem } from "../services/Api.js";
import { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import GLOBAL from '../services/global.js'

import Title from "../components/detail_view/Title.js"
import Separator from "../components/detail_view/Separator.js"
import Input from "../components/detail_view/Input.js"
import Select from "../components/detail_view/Select.js"

import i18n from "i18n-js"

import "../assets/css/pages/OneContact.css";
import "react-activity/dist/library.css";


const Contacts = () => {

  const [lead, setLead] = useState({});

  const [planList, setPlanList] = useState({});
  const [provincia_list, setProvinciaList] = useState({});
  const [country_c_list, setCountryCList] = useState({});
  const [tipopago_list, setTipopagoList] = useState({});

  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [config, setConfig] = useState({});
  const [formValues, handleInputChange, reset, setForm] = useForm({});

  const location = useLocation();
  const id = location.pathname.split("/").pop()

  const labelMaker = (x, y) => {
    //x = x == '' ? "None" : x
    return {label:x, value:y}
  }

  const createDropdownOptions = async (listName) => {

    if (!GLOBAL.screen1.state[listName]){
      await GLOBAL.screen1.createList(listName)
    }

    let optionsDictList = []

    if (!("" in Object.values(GLOBAL.screen1.state[listName]))){
      optionsDictList.push(labelMaker("", ""))
    }
    for (var key in GLOBAL.screen1.state[listName]){
      optionsDictList.push(labelMaker(GLOBAL.screen1.state[listName][key], key))
    }

    return optionsDictList
  }

  const load = async () => {
    setLoading(true)

    await createDropdownOptions('plan_list').then((r) => {console.log('plan_list',r);setPlanList(r)})
    await createDropdownOptions('provincia_list').then((r) => {console.log('provincia_list',r);setProvinciaList(r)})
    //await createDropdownOptions('country_c_list').then((r) => {console.log('country_c_list',r);setCountryCList(r)})
    //await createDropdownOptions('tipopago_list').then((r) => {console.log('tipopago_list',r);setTipopagoList(r)})

    await GLOBAL.screen1.loadConfig('Contacts').then((conf) => setConfig(conf));
    await getOneitem("Contacts", id)
    .then(res => {
      var temp_lead = res
      setLead("attributes" in res ? temp_lead.attributes : temp_lead)
      setForm("attributes" in res ? temp_lead.attributes : temp_lead)

    }).catch((err) => {
      //utils.simpleErrorPopup(err, navigation);
    })
    setLoading(false)

  }

  useEffect(() => {
    load()
  }, [])

  const renderButtons = () => {
    return (
      <div className="one-contact-buttons-div">
        <button
          className="one-contact-button one-contact-button-cancel"
          onClick={() => {setIsEditing(false); setForm(lead);}}
          >

          {i18n.t('leadsFormCancelBotton')}

        </button>
        <button
          className="one-contact-button one-contact-button-accept"
          onClick={() => {patchItem("Contact", formValues, id).then(()=>setIsEditing(false))}}
          >

          {i18n.t('leadsFormSaveBotton')}

        </button>

      </div>

    )
  }

  if (!config || loading) return <Spinner className="spinner" size={32} speed={1} animating={loading}/>
  return (
    <div className="one-contact-container">

        <div className="one-contact-title">
            <Title
              name={config.primer_nombre}
              status={i18n.t('contactsFormStatus')}
              value={formValues[config.primer_nombre]}
              onChange={handleInputChange}
              onFocus={()=>setIsEditing(true)}
              />
            {<Separator/>}
        </div>

        <div className="one-contact-inputs">

            <div className="row">
            {("telefono1" in config) && <div className="col">
                <Input
                  type="number"
                  label={i18n.t('contactsFormPhone1')}
                  name={config.telefono1}
                  value={formValues[config.telefono1]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            {("telefono1" in config) && ("telefono2" in config) && <div className="lead-space"/>}
            {("telefono2" in config) && <div className="col">
                <Input
                  type="number"
                  label={i18n.t('contactsFormPhone2')}
                  name={config.telefono2}
                  value={formValues[config.telefono2]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
            </div>

            <div className="row">
              {("email" in config) && <div className="col">
                <Input
                  type="email"
                  label={i18n.t('contactsFormEmail')}
                  name={config.email}
                  value={formValues[config.email]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                />
              </div>}
                {("producto" in config) && ("email" in config) && <div className="lead-space"/>}
              {("producto" in config) && <div className="col">
                <Select
                      data={planList}
                      label={i18n.t('contactsFormProduct')}
                      name={config.producto}
                      value={formValues[config.producto]}
                      onChange={handleInputChange}
                      onFocus={()=>setIsEditing(true)}
                    />
                </div>}
            </div>

            <div className="row">
            {("direccion" in config) && <div className="col">
              <Input
                  label={i18n.t('contactsFormStreetAddress')}
                  name={config.direccion}
                  value={formValues[config.direccion]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
              }
            
            {("ciudad" in config) && ("direccion" in config) && <div className="lead-space"/>}

            {("ciudad" in config) && <div className="col">
                <Input
                  label={i18n.t('contactsFormCity')}
                  name={config.ciudad}
                  value={formValues[config.ciudad]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
                }
            </div>

            <div className="row">
              {("provincia" in config) && <div className="col">
                <Select
                    data={provincia_list}
                    label={i18n.t('contactsFormProvince')}
                    name={config.provincia}
                    value={formValues[config.provincia]}
                    onChange={handleInputChange}
                    onFocus={()=>setIsEditing(true)}
                  /></div>
                }
              
              {("provincia" in config) && ("pais" in config) && <div className="lead-space"/>}

              {("pais" in config && !!provincia_list) && <div className="col">
                <Select
                    data={provincia_list}
                    label={i18n.t('contactsFormCountry')}
                    name={config.pais}
                    value={formValues[config.pais]}
                    onChange={handleInputChange}
                    onFocus={()=>setIsEditing(true)}
                /></div>
                }
            </div>

            <div className="row">
              {("metodo_de_pago" in config && !!provincia_list) && <div className="col">
                <Select
                    data={provincia_list}
                    label={i18n.t('contactsFormPaymentMethod')}
                    name={config.metodo_de_pago}
                    value={formValues[config.metodo_de_pago]}
                    onChange={handleInputChange}
                    onFocus={()=>setIsEditing(true)}
                  /></div>
                }

              {("metodo_de_pago" in config) && ("numero_tarjeta" in config) && <div className="lead-space"/>}

              {("numero_tarjeta" in config) && <div className="col">
                <Input
                  label={i18n.t('contactsFormCardNumber')}
                  name={config.numero_tarjeta}
                  value={formValues[config.numero_tarjeta]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
                }
            </div>

            <div className="row">
              {("nombre_tarjeta" in config) && <div className="col">
                <Input
                    label={i18n.t('contactsFormCardholderName')}
                    name={config.nombre_tarjeta}
                    value={formValues[config.nombre_tarjeta]}
                    onChange={handleInputChange}
                    onFocus={()=>setIsEditing(true)}
                  /></div>
                }
            </div>


            <div className="row">
            {("vencimiento" in config) && <div className="col">
              <Input
                  label={i18n.t('contactsFormCardExpiration')}
                  name={config.vencimiento}
                  value={formValues[config.vencimiento]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
              }
            
            {("vencimiento" in config) && ("codigo_tarjeta" in config) && <div className="lead-space"/>}

            {("codigo_tarjeta" in config) && <div className="col">
                <Input
                  label={i18n.t('contactsFormCardSecurityCode')}
                  name={config.codigo_tarjeta}
                  value={formValues[config.codigo_tarjeta]}
                  onChange={handleInputChange}
                  onFocus={()=>setIsEditing(true)}
                /></div>
                }
            </div>

            <div className="row">
            <div className="col">
                {isEditing && renderButtons()}
            </div>
            </div>

        </div>

    </div>
  );

};

export default Contacts;
