import React from 'react';
import { getOptionList, getConfig, getStates, getUserData, isSupervisor } from "../services/Api"
import default_configs from "../constants/defaultConfigs"


export default class GlobalStore extends React.Component {
    
    constructor(){
        super();
        this.state = {
            ...default_configs,
            statusList:[],
            states: [],
        };
        this.pushNotificationToken="";
        this.getPossibleStatuses();
        this.loadConfig("Leads");
        this.user = {};
        this.isSupervisor = false;
        this.selectedState = 1;
    }

    clear(){
        this.state = {
            ...default_configs,
            statusList:[],
        };
        this.pushNotificationToken="";
        this.user = {};
        this.isSupervisor = false;
    }

    async loadConfig(module){
        return await getConfig(module.toLowerCase()).then(
            (config) => {
                if (!config) return !!this.state[`config_${module}`] ? this.state[`config_${module}`] : {} ;
                this.state[`config_${module}`] = config;
                //this.state[`config_${module}`] = {...this.state[`config_${module}`], ...config}
                return config;
            }
        )
    }

    switchSelectedState(){
        this.selectedState = +!this.selectedState
    }

    loadAuthData(){
        this.apiToken = sessionStorage.getItem("APIToken")
        this.url      = sessionStorage.getItem("url")
        this.username = sessionStorage.getItem("username")
        this.password = sessionStorage.getItem("password")
    }

    async reloadUser(){
        let isSupervisor_response = await isSupervisor().catch(()=>false);
        this.isSupervisor = isSupervisor_response.response;
        this.user = await getUserData().catch();
        return this.user;
    }

    async createList(listName){
        let list = await getOptionList(listName);
        this.state[listName] = list;
        return list;
    }

    async updateList(listName){
        let list = await getOptionList(listName);
        this.setState({listName:list});
        return list;
    }

    async loadStatuses(module="Leads"){
        this.state.states = await getStates(module);
        return this.state.states;
    }

    async getPossibleStatuses(module="Leads"){
        await this.loadStatuses(module);
        let statusList = this.state.states;
        /*let statusList = []
        for (var key in possibleStatuses){
          if (key){
            if (Object.values(this.state.config_status).includes(key)){
                statusList = [...statusList, key]
            }
          }
        }*/
        if (statusList!==undefined) statusList = Object.keys(statusList);
        //this.setState({statusFlow:statusList})
        this.state.statusList=statusList;
        return this.state.statusList;
      }

}
