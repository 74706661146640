const default_configs = {
    "config_Contacts":
    {
        "ciudad": "primary_address_city",
        "codigo_tarjeta": "tarjetacodigo_c",
        "direccion": "direccion_c",
        "email": "phone_fax",
        "metodo_de_pago": "tipopago_c",
        "nombre_completo": "full_name",
        "nombre": "name",
        "primer_nombre": "first_name",
        "apellido": "last_name",
        "nombre_tarjeta": "tarjetanombre_c",
        "numero_tarjeta": "tarjetanumero_c",
        "pais": "country_c",
        "producto": "planes_c",
        "provincia": "provincia_c",
        "status": "status",
        "telefono1": "phone_mobile",
        "telefono2": "phone_home",
        "vencimiento": "tarjetavencimiento_c",
        "pay_planes_list":"pay_planes_list",
        "country_c_list":"country_c_list",
        "plan_list":"plan_list",
        "provincia_list":"provincia_list",
        "tipopago_list":"tipopago_list",
    },
    "config_Leads":
    {
        "descripcion": "description",
        "direccion": "direccion_c",
        "email": "phone_fax",
        "nombre_completo": "full_name",
        "nombre": "name",
        "primer_nombre": "first_name",
        "apellido": "last_name",
        "producto": "planes_c",
        "status": "status",
        "telefono1": "phone_mobile",
        "telefono2": "phone_home",
        "plan_list": "plan_list",
    },
    "config_Calls":
    {
        "status": "status",
        "name": "name",
        "parent_name":"parent_name",
        "title_options": [
            "Mensaje",
            "Llamada",
            "Visita",
        ],
        //"agendaListMensaje": "Mensaje",
        //"agendaListLlamada": "Llamada",
        //"agendaListVisita": "Visita",
    },
    "config_Dropdown":
    [
        "Disponible",
        "Negociacion",
        "EnProceso",
        "Pre_Venta",
        "No_Trabajable",
        "Venta"
    ],
    "config_Flujo":
    [
        "Disponible",
        "Negociacion",
        "EnProceso",
        "Pre_Venta",
    ],
    "config_Users":{
        "user_name": "user_name",
    }

}

export default default_configs;