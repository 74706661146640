import { Link } from "react-router-dom";
import { Fragment } from "react";
import '../assets/css/components/TaskCard.css';
import { patchItem } from "../services/Api";
import { useState } from "react";
import i18n from "i18n-js"



const patchCall = (status, setStatus, id) => {
  var newStatus = status==="Planned"?"Held":"Planned"

  if (window.confirm(status==="Planned"?i18n.t("agendaAlertTaskDoneConfirmTitle"):i18n.t("agendaAlertTaskUnDoneConfirmTitle"))){
    setStatus(newStatus)
    patchItem("Call", {status:newStatus}, id)
  }

}


const calcDate = (date) => {

  const dateObj = new Date(date);

  return (dateObj.getFullYear()+
          "/"+
          (dateObj.getMonth()+1)+
          "/"+
          dateObj.getDate()+
          " "+
          (dateObj.getHours()<10?"0":"")+dateObj.getHours()+
          ":"+
          (dateObj.getMinutes()<10?"0":"")+
          dateObj.getMinutes()
          );

}

const TaskCard = (props) => {

  const { navigateTo } = props;
  const { name, date_start, status, parent_name } = props.task.attributes;
  const [statusHook, setStatus] = useState(status);

  let logo = "ph-fill ph-user-circle"
  switch (name){
    case "Llamada":
      logo = "ph-fill ph-phone-outgoing"
      break;
    case "Mensaje":
      logo = "ph-fill ph-whatsapp-logo"
      break;
    default:
      logo = "ph-fill ph-user-circle"
  }

  return (
    <div className="task-card">
      <Link className="task-card-items" to={!!navigateTo?navigateTo+"/detail":null}>
          <div className="name-and-logo">
            <i className={logo}></i>
            <div className="task-card-text">
              <Fragment>{name}</Fragment><br/>
              <p className="task-card-parent-name">{parent_name}</p>
            </div>
          </div>

          <div className="task-card-text">
            <Fragment>{calcDate(date_start)}</Fragment>
          </div>
          <div/>
      </Link>

      <button className="task-card-button" onClick={()=>patchCall(statusHook,setStatus,props.task.id)}>
        <p>{statusHook==="Held"?"▣":"□"}</p>
      </button>

    </div>
  )
};

export default TaskCard;
