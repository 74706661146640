/*
import { Spinner } from "react-activity";
import GLOBAL from '../services/global.js'
*/
import GLOBAL from '../services/global.js'

import { PagedSearch, getOneitem, postCall } from "../services/Api.js";

import { useState, useEffect } from "react";

import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from "../hooks/useForm";

import Input from "../components/detail_view/Input.js"
import AsyncSelect from "react-select/async"
import Select from "../components/detail_view/Select.js"

import "../assets/css/pages/CreateTask.css";
import "react-activity/dist/library.css";
import I18n from "i18n-js";


const CreateTask = () => {

  const [params] = useSearchParams();
  const navigation = useNavigate();

  const date = params.has("date") ? params.get("date") : new Date()
  .toISOString().slice(0,10);
  const time = new Date()
  .toLocaleTimeString("UTC", {hourCycle:"h24",timeStyle:"short"});

  const id = params.get("id");
  const [formValues, handleInputChange, reset, setForm] = useForm({
    name:"",
    date:date,
    time:time,
    parent_id:"",
  });
  const [lead, setLead] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const searchObject = new PagedSearch(20, "Leads");
  const [titleData, setTitleData] = useState([]);

  const renderButtons = () => {
    return (
      <div className="one-lead-buttons-div">
        <button
          className="one-lead-button one-lead-button-cancel"
          onClick={() => {reset();navigation(-1)}}
          >

          {I18n.t('leadsFormCancelBotton')}

        </button>
        <button
          className="one-lead-button one-lead-button-accept"
          onClick={() => {
            const [year, month, day] = formValues.date.split("-");
            formValues["date_start"] = day+"/"+month+"/"+year+" "+formValues.time;
            formValues["parent_id"] = formValues["parent_id"].id;
            postCall(formValues);
            navigation(id!==""?"/tasks":"/");
          }}
          >

          {I18n.t('leadsFormSaveBotton')}

        </button>

      </div>

    )
  }

  const loadOptions = async (search) => {
    searchObject.newSearch(search);
    let data = await searchObject.next();
   return data;
  }

  useEffect(() => {
    if (!id) return;
    getOneitem("Leads", id)
      .then(res => {
        var temp_lead = res
        temp_lead = "attributes" in res ? temp_lead.attributes : temp_lead
        temp_lead["id"] = id

        handleInputChange({target:{value:temp_lead, name:"parent_id"}})

      }).catch((err) => {
        //utils.simpleErrorPopup(err, navigation);
    })
  }, [id])

  useEffect(() => {
    GLOBAL.screen1.loadConfig('Calls').then((conf) => setTitleData(buildTitleDropdown(conf)))
  }, [])

  useEffect(() => {
    setForm({...formValues, name:titleData.length!==0?titleData[0].value:""})
  }, [titleData])

  const labelMaker = (x, y) => {
    //x = x == '' ? "None" : x
    return {label:x, value:y}
  }
  const buildTitleDropdown = (config) => {
    if (!config.title_options) return [];

    return config.title_options.map((i)=>{
      let label = I18n.t('agendaList'+i);
      if (label.startsWith("[missing")) label = i;
      return labelMaker(label, i);
    });

  }


  return (
    <div className="create-task-input-container">

      <Select
        label={I18n.t("agendaFormTask")}
        name={"name"}
        value={formValues["name"]}
        onChange={handleInputChange}
        data={!!titleData?titleData:[]}
      />


      <div className="row">

            <Input
              type="date"
              label={I18n.t("agendaFormDateTime")}
              name={"date"}
              value={formValues["date"]}
              onChange={handleInputChange}
            />

            <Input
              type="time"
              label={<br/>}
              name={"time"}
              value={formValues["time"]}
              onChange={handleInputChange}
            />

        </div>

        <div className="create-task-user-select-div">

          <p className="create-task-user-select-label">{I18n.t("agendaFormAssignedTo")}</p>

          <AsyncSelect
            styles={{control: ( baseStyles ) => ({
              ...baseStyles,
              borderColor: "#1D1F66",
            }),}}
            onInputChange={(val)=>setSearchInput(val)}
            loadOptions={loadOptions}
            data={lead}
            name={"parent_id"}
            value={formValues["parent_id"]}
            onChange={(value) => handleInputChange({target:{value:value, name:"parent_id"}})}
            isDisabled={!!id}
            placeholder={!!id?id:""}

            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.id}
          />
        </div>

          <div className="row">
            <div className="col">
                {renderButtons()}
            </div>
          </div>

    </div>
  );
};

  
export default CreateTask;
