/*

Auth request - Pegada a la api del CRM para obtener el token de autenticacion

ToDo:

  ▣ Usar libreria de expo-secure-store para almacenar el token y las credenciales
  ▣ Si se quiere adaptar la app a web hay que almacenarlo de otra manera. expo-secure-store no es para web
  □ Refactorizar para manejar los errores de forma que al app sea agnostica
  □ Sacar getList, no deberia estar en el archivo auth (o cambiar el nombre del archivo, lo q convenga)
  ▣ paginar getList
  ▣ Buscar id de usuario logeado
  ▣ Cambiar getUserData
  ▣ post y patch a producto_c/plan_c no existe (posiblemente por que no se que opciones son validas)

*/


import axios from 'axios';
import GLOBAL from './global.js';


export const auth = async (username, password, url)=>{
    // const error = formValidation(form);
    const user = {
      "grant_type" : "password",
      "client_id" : "f950042d-3555-ba47-ebd2-640a0f6022a9",//"d8d8fd8e-2a0f-0fe8-aef5-635fd62a96c3",
      "client_secret" : "n0l4s3ch3",
      "username" : username,
      "password" : password
    }


    return await axios({
          method: "POST",
          url: url + "/Api/index.php/access_token",
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
          },
          data: user,
        })

        .then((res) => {
          save(username, password, url, res.data.access_token)
          return res.status
        })

        .catch((err) => {
          return err.request.status // manejar error
        });

}


const save = async (username, password, url, apiToken)=>{
  sessionStorage.setItem("APIToken", apiToken)
  sessionStorage.setItem("url", url)
  sessionStorage.setItem("username", username)
  sessionStorage.setItem("password", password)
  if (!!GLOBAL.screen1) GLOBAL.screen1.loadAuthData()
}


export const isAuth = async ()=>{

  let token = sessionStorage.getItem("APIToken")
  // si token existe:
  if (token != null){

    //GLOBAL.user=null
    let user = await getUserData()

    // si pegada anda
    let token_works = (!!user && "id" in user);
    if (token_works){
      return true

    // si pegada no anda
    }else{

        let password = sessionStorage.getItem("password")
        let username = sessionStorage.getItem("username")
        let url = sessionStorage.getItem("url")

      // hacer auth con los datos en storage
      // si anda devuelve true
      if (await auth(username, password, url) == 200){
        return true
      }
    }
  }

  // si nada anda devuelve false
  // token no existe o token no es valido y usuario y pass tampoco
  return false
}


export const getUserData =  async ()=>{

  if (!!GLOBAL.user){
    return GLOBAL.user
  }
  // busco el url en memoria
  let url = sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return} // manejar el error
  let token = sessionStorage.getItem("APIToken")

  // agrego al url para hacer la request al endpoint correcto
  url = url + `/Api/index.php/V8/current-user`

  var resp = await fetch(url, {
    method: "GET",
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    })
    .then(response => response.json())
    .catch(err => {
      if (!err.request) throw {response:"error getting user data", error_code:"GET_USER"} // manejar error
      throw {response:"error getting user data", error_code:"GET_USER", status:err.request.status} // manejar error
    });

    let data = "data" in resp ? JSON.parse(JSON.stringify(resp)).data.attributes : resp // horrible

    GLOBAL.user = data;
    return data;

}


// getList no deberia estar en el archivo auth
export const getList = async (module, pageSize=null, pageNumber=null, status=null, dateStart=null, dateEnd=null, filterDate=true, filters=null, sort=null)=>{
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)

  // busco el url en memoria
  let url = sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  // agrego al url para hacer la request al endpoint correcto
  url = url + `/Api/index.php/V8/module/${module}?`

  // si hay tamaño de pantalla agregar el query al url
  if (pageSize != null && pageNumber != null){
    url = url + `page[size]=${pageSize}&page[number]=${pageNumber}`
  }

  // si hay filtro de estado agregar el query al url
  if (!!status){
    url = url + `&filter[status][eq]=${status}`
  }

  // Si no hay fecha se pone el rango default (1 mes)
  var date = new Date();
  if (dateEnd == null && filterDate){
    dateEnd = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()+1}`;
  }

  // Si no hay fecha se pone el rango default (1 mes)
  if (dateStart == null && filterDate){
    //date.setMonth(date.getMonth() - 1)
    date.setDate(date.getDate() - 30)

    //prueba
    //date.setFullYear(date.getFullYear() - 2)

    dateStart = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  }

  // se agrega el filtro de las fechas al url
  if (filterDate){
    url = url + `&filter[date_entered][GT]=${dateStart}and&filter[date_entered][LT]=${dateEnd}`
  }

  if (!!sort){
    url = url + `&sort=${sort}`
  }

  // se agregan los filtros
  if (filters != null){
    for (var key in filters){
      // el argumento de filtros tiene que tener la siguiente forma:
      // {[key, comparacion, value]} => por ejemplo => [["date_entered","GT", "2022-12-20"]] => filter[date_entered][GT]=2022-12-20
      url = url + `&filter[${filters[key][0]}][${filters[key][1]}]=${filters[key][2]}` // manejar errores

      if (filters[key][3] != "" && filters[key][3] != undefined){
        url = url + `&filter[operator]=${filters[key][3]}`
      }
    }
  }

  // busco el token en memoria
  let token = sessionStorage.getItem("APIToken")
  // hago el request
  var res = await axios({
    baseURL:"",
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    
  })
  .then((response) => response.data)
  .catch((err) => {
    throw {response:"error getting list", error_code:"GET_LIST", status:err.request.status} // manejar error
  });
  //console.log(url,res)
  return res

}


export const postLead = async (lead, type="Lead")=>{

  //if ("nombre" in lead && "apellido" in lead && "telefono" in lead ){return}
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/Api/index.php/V8/module`
  // busco el token en memoria
  let token = sessionStorage.getItem("APIToken")

  var userData = await getUserData()

  var data = {
    "data":{
            "type": type,
            "attributes": {
                //"name": lead.full_name,
                //"description": lead.description,
                ////"last_name": lead.last_name,
                //"full_name": lead.full_name,
                //"phone_fax": lead.phone_fax,
                //"phone_mobile": lead.phone_mobile,
                //"phone_home": lead.phone_home,
                //"direccion_c": lead.direccion_c,
                //"planes_c": lead.planes_c,
                ...lead,
                "assigned_user_id": userData.id, // id del usuario logeado
                "lead_source": "Referido",
                //"status": lead.status,
            }
        }
  }
  return await axios({
    method: "POST",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    
    data: data,
  })

  .then((res) => {
    return res.status
  })

  .catch((err) => {
    throw {response:"error creating entry", error_code:"POST_LEAD", status:err.request.status, full:JSON.stringify(err)} // manejar error
  });

}


export const patchItem = async (type, lead, id)=>{

  //if ("nombre" in lead && "apellido" in lead && "telefono" in lead ){return}
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/Api/index.php/V8/module`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  var attributes = {}
  if (!!GLOBAL.screen1.state["config_"+type+"s"]){
    for (var key in lead){
      if (Object.values(GLOBAL.screen1.state["config_"+type+"s"]).includes(key)){
          attributes[key] = lead[key]
      }
    }
  }else{
    attributes = lead
  }


  var data = {
    "data":{
            "type": type,
            "id": id,
            "attributes": attributes
        }
  }

  return await axios({
    method: "PATCH",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},

    data: data,
  })

  .then((res) => {
    return res.status
  })

  .catch((err) => {
    //console.log(JSON.stringify(err))
    throw {response:"error editing entry", error_code:"PATCH_ITEM", status:err.request.status} // manejar error
  });

}


export const searchLeads = async (module, query, page_number=null, page_size=null, abortController=null)=>{

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)

  // busco el url en memoria
  let url = await sessionStorage.getItem("url")

  // si no hay url no hace el request
  if (url == null){return}
  let user = await getUserData();

  // agrego al url para hacer la request al endpoint correcto
  url = url + `/index.php?entryPoint=SearchEntryPoint&module=${module}&q=${query}`

  if (page_number != null) url = url + `&page_number=${page_number}`
  if (page_size != null) url = url + `&page_size=${page_size}`

  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    signal: abortController.signal,

    //cancelToken: new axios.CancelToken(c => cancel = c)
  })
  .then((res) => {
    return res.data
  })
  .catch((err) => {
    throw {response:"error on search", error_code:"SEARCH", status:err.request.status, e:err.code} // manejar error
  });

  return res

}


export const getStates = async (module) => {

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)

  // busco el url en memoria
  let url = await sessionStorage.getItem("url")

  // si no hay url no hace el request
  if (url == null){return}

  let user = await getUserData()
  // agrego al url para hacer la request al endpoint correcto
  url = url + `/index.php?entryPoint=StatusValuesEntryPoint&user=${user.id}&module=${module}`

  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => res.data)
  .catch((err) => {
    throw {response:"error getting CRM status values", error_code:"GET_STATUS", status:err.request.status} // manejar error
  });

  return res

}


export const getOptionList = async (listName) => {

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)

  // busco el url en memoria
  let url = await sessionStorage.getItem("url")

  // si no hay url no hace el request
  if (url == null){return}

  // agrego al url para hacer la request al endpoint correcto
  url = url + `/index.php?entryPoint=ListValuesEntryPoint&list=${listName}`

  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => res.data)
  .catch((err) => {
    throw {response:"error getting list options", error_code:"GET_OPTIONS", status:err.request.status} // manejar error
  });

  return res

}


export const getOneitem = async (module, id)=>{
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)

  // busco el url en memoria
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  // agrego al url para hacer la request al endpoint correcto
  url = url + `/Api/index.php/V8/module/${module}/${id}`

  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(response => response.data.data)
  .catch((err) => {
    throw {response:"error getting entry", error_code:"GET_ONEITEM", status:err.request.status} // manejar error
  });

  return res

}


export const getConfig = async (module) => {

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)

  // busco el url en memoria
  let url = await sessionStorage.getItem("url")

  // si no hay url no hace el request
  if (url == null){return}

  // agrego al url para hacer la request al endpoint correcto
  url = url + `/index.php?entryPoint=AppConfigEntryPoint&module=${module}`

  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => {
    try{
      res = JSON.parse(res.data)
    }catch{
      res = res.data
    }
    return res
  })
  .catch((err) => {
    throw {response:"error getting configurations", error_code:"GET_CONFIG", status:err.request.status} // manejar error
  });
  return res

}


export const postCall = async (toSend, type="Call")=>{

  //if ("nombre" in lead && "apellido" in lead && "telefono" in lead ){return}
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/Api/index.php/V8/module`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  var userData = await getUserData()

  var data = {
      "data": {
        "type": type,
        "attributes": {
                "name": toSend.name,

                "description": toSend.description,
                "assigned_user_id": userData.id,    
                "duration_hours": toSend.duration_hours,
                "duration_minutes": toSend.duration_minutes,

                "date_start": toSend.date_start,

                "parent_type": "Leads",

                "status": "Planned",
                "direction": "Outbound",

                "parent_id": toSend.parent_id

        }

      }
  }

    return await axios({
      method: "POST",
      url: url,
      withCredentials: false,
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      data: data,
    })

    .then((res) => {      
      return res.status
    })

    .catch((err) => {
      throw {response:"error creating entry", error_code:"POST_CALL", status:err.request.status} // manejar error
    });

}


// Leads/2033bdc0-8837-48db-84f7-2ac3d2dabc65/relationships/contacts
export const getRelationship = async (module, id, relatedModule) => {
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/Api/index.php/V8/module/${module}/${id}/relationships/${relatedModule.toLowerCase()}`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => res.data)
  .catch((err) => {
    throw {response:"error getting relationship", error_code:"GET_RELATIONSHIP", status:err.request.status} // manejar error
  });

  return res
}


export const queueLogin = async (action) => {
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/index.php?entryPoint=PBXQueue&queue=${action}`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "POST",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => res.data)
  .catch(err => err.response.data);

  return res
}


export const isInQueue = async () => {

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/index.php?entryPoint=PBXQueue`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => res.data)
  .catch(err => err.response.data);

  return res

}

export const getPBXUserData = async (number, email) => {

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/index.php?entryPoint=PBXUser`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "POST",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    data:{ number:number, email:email }
  })
  .then(res => res.data.response)
  .catch(err => err.response.data);

  //console.log(res)
  //console.log(number, email)
  return res

}


export const isSupervisor = async () => {

  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/index.php?entryPoint=IsUserSupervisorEntryPoint`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  // hago el request
  var res = await axios({
    method: "GET",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })
  .then(res => res.data)
  .catch(err => {
    throw {response:false, error_code:"IS_SUPERVISOR", status:err.request.status}
  });
  return res

}


export const deleteItem = async (type, id)=>{

  //if ("nombre" in lead && "apellido" in lead && "telefono" in lead ){return}
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/Api/index.php/V8/module/${type}/${id}`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  return await axios({
    method: "DELETE",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
  })

  .then((res) => {
    return res.status
  })

  .catch((err) => {
    throw {response:"error deleting item", error_code:"DELETE_ITEM", status:err.request.status} // manejar error
  });

}

export const sendQRMail = async (user_id, password) => {

  //if ("nombre" in lead && "apellido" in lead && "telefono" in lead ){return}
  if (!isAuth()){return} // manejar error (solo va a entrar si no hay token en memoria)(o si no hay internet)
  let url = await sessionStorage.getItem("url")
  // si no hay url no hace el request
  if (url == null){return}
  url = url + `/index.php?entryPoint=SendQRMailEntryPoint`
  // busco el token en memoria
  let token = await sessionStorage.getItem("APIToken")

  var data = {
            "recipient": user_id,
            "password":password,
          }

  return await axios({
    method: "POST",
    url: url,
    withCredentials: false,
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},

    data: data,
  })

  .then((res) => {
    return res.status
  })

  .catch((err) => {
    throw {response:"error sending mail", error_code:"POST_QR_MAIL", status:err.request.status} // manejar error
  });

}


export class PagedSearch {
  constructor(page_size, module, query="") {
    this.page_size = page_size;
    this.module = module;
    this.currentPage = -1;
    this.loadedPages = [];
    this.query = query;
    this.abortController = new AbortController();
    this.loading = false;
  }

  async loadPage(pageNumber) {
    if (this.loadedPages.includes(pageNumber)) {
      return;
    }
    this.loading = true
    const data = await searchLeads(this.module, this.query, pageNumber, this.page_size, this.abortController)
    this.loadedPages.push(pageNumber);
    this.loading=false;

    return data;
  }

  async next() {
    const nextPageNumber = this.currentPage + 1;
    var data;
    try{
      data = await this.loadPage(nextPageNumber);
      this.currentPage = nextPageNumber;
    }
    catch(e){
      data = [];
    }
    return data;
  }

  newSearch(q){
    this.abortController.abort()

    this.currentPage = -1;
    this.loadedPages = [];
    this.query = q;
    this.abortController = new AbortController();
  }

}
