
import '../../assets/css/components/detail_view/Select.css';


const Select = (props) => {

  const { label, data } = props;

  return (
    <span className="select-container">
        <p className='select-label'> {label} </p>
        <select
          {...props}
          className={'select-component'}
        >
          {data.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
        </select>
    </span>
  )

};

export default Select;
