import { Outlet, Link, useLocation } from "react-router-dom";
import '../assets/css/components/TopBar.css';
import { useState, useEffect } from "react";
import GLOBAL from '../services/global.js'
import { Spinner } from "react-activity";


const TopBar = () => {

  const path = useLocation() ;

  const status_path = path.pathname.split("/").pop()

  const [states, setStates] = useState({});
  const [allowedStates, setAllowedStates] = useState([])

  useEffect(() => {
    GLOBAL.screen1.loadConfig('Flujo').then((r) =>
      GLOBAL.screen1.getPossibleStatuses().then(()=>
        {
          setStates(GLOBAL.screen1.state.states)
          setAllowedStates(Object.keys(GLOBAL.screen1.state.states).filter((i) =>
            r.includes(i)
          ))
        }
      )
    )
  }, [setStates,setAllowedStates]);

  //await GLOBAL.screen1.loadConfig('Flujo');
  //let i = GLOBAL.screen1.state.config_Flujo;
  return (
    <>
      <nav className="top-menu-container">
        <ul className="top-menu">
          {
            !states?<Spinner className="spinner" size={32} speed={1} animating={true} />
            :allowedStates.map((k)=>            
              <li key={k}>
                <Link key={k} className={status_path === k ? "top-menu-link-selected":"top-menu-link"} to={"/leads/"+k}>
                    <h1>{states[k]["label"]}</h1>
                </Link>
              </li>
            )
          }
        </ul>
      </nav>

      <Outlet key={path.pathname} />
    </>
  )
};

export default TopBar;
