
import '../assets/css/components/List.css';


const List = (props) => {
    const { keyExtractor, data, itemMapper } = props;

    const items = data.map((item) =>
        <li key={keyExtractor(item)}>
            {itemMapper(item)}
        </li>
        )

    return (
        <div className="list-container">
            <ul className="list">
                {items}
            </ul>
        </div>
    )
};

export default List;
