
import { useState, useEffect } from "react";

import GLOBAL from '../services/global.js'
import i18n from "i18n-js"
import Separator from "../components/detail_view/Separator.js"
import { Link } from "react-router-dom";
import { useModal } from '../hooks/useModal';
import { ModalBody, ModalHeader } from 'reactstrap';
import { Modal } from '../components/Modal';
import { useForm } from "../hooks/useForm";
import { Spinner } from "react-activity";


import "../assets/css/pages/Settings.css";


const Settings = () => {

  const [user, setUser] = useState({});
  const [isOpenModal, openModal, closeModal] = useModal(false);


  useEffect(() => {
    GLOBAL.screen1.reloadUser()
    .then((r)=>setUser(r))
  }, [])


  return (
    <div className="settings-container">

    { !user.full_name && <Spinner className="spinner" size={16} speed={1} animating={true}/> ||
      <h1 className="settings-user-fullname">{user.full_name}</h1>
    }
    <p className="settings-username">{i18n.t("settingsUser")}: {user.user_name}</p>
    <Separator/>

    <div className="settings-language-popup">
      <Modal isOpen={isOpenModal} closeModal={closeModal}>
          <ModalHeader style={{display: 'block', borderBottom:'#fff'}}>
              <span style={{float: 'right'}}></span>
              <h1 className="settings-modal-title">{i18n.t("settingsLanguagePopupTitle")}</h1>
              <Separator className="settings-modal-separator"/>
          </ModalHeader>
          <ModalBody>

            <form className="settings-language-select-form">

              <input type="radio" name="language" value="es"
              onChange={({ target })=>i18n.locale=target.value}
              />
              <label for="es">Español</label><br/>

              <input type="radio" name="language"  value="en"
              onChange={({ target })=>i18n.locale=target.value}
              />
              <label for="en">Ingles</label><br/>

              <input type="radio" name="language"  value="pt-BR"
              onChange={({ target })=>i18n.locale=target.value}
              />
              <label for="pt-BR">Portugues</label><br/>

              <div className="settings-language-select-button-container">
              <button className="settings-language-select-button" onClick={()=>{sessionStorage.setItem("lang", i18n.locale);window.location.reload();}}>{i18n.t("menuAccept")}</button>
              </div>

            </form>

          </ModalBody>
      </Modal>
    </div>

    <div className="settings-button-container">
      {/* --- Boton para seleccionar idioma --- */}
      <Link
            className="settings-change-language-button"
            onClick={() => {openModal()}}
            >

            <i className="ph-fill ph-globe-hemisphere-west"/>
            {i18n.t('settingsLanguage')} {">"}

      </Link><br/>

      {/* --- Boton para ver usuarios --- */}
      {GLOBAL.isSupervisor && <div>
      <Link
            className="settings-view-users-button"
            onClick={() => {}}
            >
            <i className="ph-fill ph-user-circle-gear"/>
            {i18n.t('settingsViewUsers')}

      </Link><br/></div>}

      {/* --- Boton para recargar configuracion ---*/}
      {/*
      <Link
            className="settings-reload-config-button"
            onClick={() => {}}
            >

            <i className="ph-fill ph-gear-fine"/>
            {i18n.t('settingsUpdate')}

      </Link><br/>*/}

      {/* --- Boton para cerrar sesion ---*/}
      <Link
            className="settings-logout-button"
            onClick={() => {
              sessionStorage.clear();
              GLOBAL.screen1.clear();
              window.location.reload();
            }}
            >

            <i className="ph-fill ph-sign-out"/>
            {i18n.t('settingsLogout')}

      </Link><br/>
    </div>
    </div>
  );
};

export default Settings;
