import { useForm } from "../hooks/useForm.js";
import { postLead } from "../services/Api.js";
import { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import GLOBAL from '../services/global.js'
import { useNavigate } from 'react-router-dom';

import Input from "../components/detail_view/Input.js"
import Select from "../components/detail_view/Select.js"

import i18n from "i18n-js"

import "../assets/css/pages/OneLead.css";
import "react-activity/dist/library.css";


const OneLead = () => {
  const navigation = useNavigate();

  const [statusOptions, setStatusOptions] = useState({});
  const [statusObject, setStatusObject] = useState({});
  const [planList, setPlanList] = useState({});

  const [loading, setLoading] = useState(false);

  const [config, setConfig] = useState({});
  const [formValues, handleInputChange, reset, setForm] = useForm({status:"Disponible"});


  const load = async () => {
    setLoading(true)

    await GLOBAL.screen1.loadConfig('whatsapp_url');
    createStatusOptions();
    let r = await GLOBAL.screen1.loadStatuses();
    setStatusObject(r);
    await createDropdownOptions('plan_list').then((r) => setPlanList(r))
    await GLOBAL.screen1.loadConfig('Leads').then((conf) => setConfig(conf));

    //setForm({[config.status]:""})

    setLoading(false)
  }

  useEffect(() => {
    load()
  }, [])

  const labelMaker = (x, y) => {
    //x = x == '' ? "None" : x
    return {label:x, value:y}
  }
  // crea las opciones para el dropdown de estado
  const createStatusOptions = async () => {

    // busca los estados en memoria
    let statusList = GLOBAL.screen1.state.statusList
    if (!statusList){
      statusList = await GLOBAL.screen1.getPossibleStatuses()
    }

    await GLOBAL.screen1.loadConfig('Dropdown');
    let drop_down_options = GLOBAL.screen1.state.config_Dropdown;

    let options = statusList.filter(item => drop_down_options.includes(item));

    // crea el diccionario necesario para usar los estados como opciones del dropdown
    let optionsDictList = options.map(option => labelMaker(GLOBAL.screen1.state.states[option]["label"], option))

    // hace un setState para actualizar la lista de opciones del estado
    setStatusOptions(optionsDictList)
  }

  const createDropdownOptions = async (listName) => {

    if (!GLOBAL.screen1.state[listName]){
      await GLOBAL.screen1.createList(listName)
    }

    let optionsDictList = []

    if (!("" in Object.values(GLOBAL.screen1.state[listName]))){
      optionsDictList.push(labelMaker("", ""))
    }
    for (var key in GLOBAL.screen1.state[listName]){
      optionsDictList.push(labelMaker(GLOBAL.screen1.state[listName][key], key))
    }
  
    // hace un setState para actualizar la lista de opciones del estado
    //this.setState({...this.state, statusOptions:optionsDictList})
    return optionsDictList
  }


  const renderButtons = () => {
    return (
      <div className="one-lead-buttons-div">
        <button
          className="one-lead-button one-lead-button-cancel"
          onClick={() => {reset();navigation(-1)}}
          >

          {i18n.t('leadsFormCancelBotton')}

        </button>
        <button
          className="one-lead-button one-lead-button-accept"
          onClick={() => {
            formValues[config.primer_nombre] = formValues[config.nombre]
            formValues[config.nombre_completo] = formValues[config.nombre]
            postLead(formValues);
            navigation(-1);
          }}
          >

          {i18n.t('leadsFormSaveBotton')}

        </button>

      </div>

    )
  }

  if (!config || loading) return <Spinner className="spinner" size={32} speed={1} animating={loading}/>
  return (
    <div className="one-lead-container">

        <div className="one-lead-title">
            <Input
              label={i18n.t('leadsFormFullName')}
              name={config.nombre}
              value={formValues[config.nombre]}
              onChange={handleInputChange}
              />
        </div>

        <div className="one-lead-inputs">

            <div className="row">
            {("telefono1" in config) && <div className="col">
                <Input
                  type="number"
                  label={i18n.t('leadsFormPhone1')}
                  name={config.telefono1}
                  value={formValues[config.telefono1]}
                  onChange={handleInputChange}
                />
              </div>}
            {("telefono2" in config) && <div className="col">
                <Input
                  type="number"
                  label={i18n.t('leadsFormPhone2')}
                  name={config.telefono2}
                  value={formValues[config.telefono2]}
                  onChange={handleInputChange}
                />
              </div>}
            </div>

            <div className="row">
            <div className="col">
                {("email" in config) && <div className="col">
                <Input
                  type="email"
                  label={i18n.t('leadsFormEmail')}
                  name={config.email}
                  value={formValues[config.email]}
                  onChange={handleInputChange}
                />
              </div>}
            </div>
            </div>


            <div className="row">
            {("status" in config) && <div className="col">
              <Select
                  data={statusOptions}
                  label={i18n.t('leadsFormStatus')}
                  name={config.status}
                  value={formValues[config.status]}
                  onChange={handleInputChange}
                />
              </div>}
            {("subestado" in config) && <div className="col">
                <Select
                  data={statusObject[formValues[config.status]]["substatus"]}
                  label={i18n.t('leadsFormSubstatus')}
                  name={config.subestado}
                  value={formValues[config.subestado]}
                  onChange={handleInputChange}
                />
              </div>}
            </div>

            <div className="row">

            {("producto" in config) && <div className="col">

            <Select
                  data={planList}
                  label={i18n.t('leadsFormProduct')}
                  name={config.producto}
                  value={formValues[config.producto]}
                  onChange={handleInputChange}
                />

              </div>}
            </div>

            <div className="row">
            <div className="col">
                {("descripcion" in config) && 
                <Input
                  label={i18n.t('leadsFormDescription')}
                  name={config.descripcion}
                  value={formValues[config.descripcion]}
                  onChange={handleInputChange}
                  tall={+true}
                />
              }
            </div>
            </div>

            <div className="row">
            {("direccion" in config) && <div className="col">
              <Input
                  label={i18n.t('leadsFormHomeAddress')}
                  name={config.direccion}
                  value={formValues[config.direccion]}
                  onChange={handleInputChange}
                /></div>
              }
            {("provincia" in config) && <div className="col">
                <Input
                  label={i18n.t('leadsFormHomeAddress')}
                  name={config.provincia}
                  value={formValues[config.provincia]}
                  onChange={handleInputChange}
                /></div>
                }
            </div>

            <div className="row">
            {("ciudad" in config) && <div className="col">
                <Input
                  label={i18n.t('leadsFormCity')}
                  name={config.ciudad}
                  value={formValues[config.ciudad]}
                  onChange={handleInputChange}
                />
            </div>}
            </div>

            <div className="row">
            <div className="col">
                {renderButtons()}
            </div>
            </div>

        </div>

    </div>
  );

};

export default OneLead;
