import React from "react";
import { useState, useEffect } from "react";
import Separator from "../components/detail_view/Separator.js"
import TaskCard from '../components/TaskCard';
import { Spinner } from "react-activity";

import '../assets/css/components/Historial.css';


import { getList } from "../services/Api";
import I18n from "i18n-js";


const Historial = (props) =>  {

    const {id} = props;
    const [loading, setLoading] = useState(false);

    const [tasksPlanned, setTasksPlanned] = useState([]);
    const [tasksHeld, setTasksHeld] = useState([]);

    useEffect(() => {
        createList()
      }, [])    

    /*/ metodo para recargar cuando se desliza hacia arriba
    onRefresh = () => {
        // indico que se esta refrescando
        this.setState({...this.state, refreshing:true})
        // reseteo la paginacion y la lista de elementos

        // cargo la lista de elementos y si es exitoso el request indico que se termino de recargar
        this.createList().then(this.setState({...this.state, refreshing:false})) // se debe hacer manejo de errores
    }
    //*/

    const createList = async () => {

      setLoading(true);

      setTasksPlanned([]);
      setTasksHeld([]);

      // listaPlanned + ?filter[date_start][GT]=Hoy
      var filterPlanned = [["status","EQ", "Planned",],["parent_id", "EQ", id]]
      var listaPlanned = await getList("Calls", null, null, null, null, null, false, filterPlanned, "-date_start").then(r => r.data)
  
      // Lista hoy + ?filter[date_start][EQ]=Hoy
      var filterHeld = [["status","EQ", "Held",], ["parent_id", "EQ", id]]
      var listaHeld = await getList("Calls", null, null, null, null, null, false, filterHeld, "-date_start").then(r => r.data)
  
      setTasksPlanned(listaPlanned);
      setTasksHeld(listaHeld);

      setLoading(false);
    }

    //
    const renderCards = (list, title) => {
        if (list.length === 0) return
        return (
            <div>
                <h2 className="historial-card-list-title">{title}</h2>
                {list.map(item => renderCard(item))}
            </div>
        )
    }
    //*/

    //
    const renderCard = (item) => {
        return(
                <TaskCard task={item}/>
        );
    }
    //*/

    /*/
    renderIndicator(){
        if (this.state.loading || this.state.refreshing){
            return <ActivityIndicator color={Theme.COLORS.PRIMARY} size="large"/>
        }
    }
    //*/

    //
    const emptyComponent=()=>{
      // si se esta cargando los elementos se muestra un mensaje distinto a si no existe ninguno (poner un spinner si te gusta franquito)
      if (loading){
        return <div className="historial-empty"><Spinner className="spinner" size={32} speed={1} animating={loading}/></div>;
      }
      return(
        <div>
            <p className="historial-empty">{I18n.t("leadHistoryEmpty")}</p>
        </div>
      );
    }
    //*/


    return (
    <div className="historial-container">
        <div>
            <h1 className="title-input">
                {I18n.t("leadHistoryTitle")}
            </h1>
        </div>
        <Separator/>

        <div className="historial-screen-scroll">
            <div style={{paddingBottom:120}}>
                {tasksPlanned.length < 1 && tasksHeld.length < 1 && emptyComponent()}
                {renderCards(tasksPlanned, I18n.t("leadHistoryPlanned"))}
                {renderCards(tasksHeld, I18n.t("leadHistoryHeld"))}
            </div>
        </div>
    </div>
    );

  }



export default Historial;
