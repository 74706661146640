import '../../assets/css/components/detail_view/Separator.css';


const Separator = (props) => 
  {
    const {className} = props;
      return (
        <div
          className={'separator ' + className}
        />
    )
  }
    

  export default Separator;
  