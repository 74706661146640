import { Link } from "react-router-dom";
import Separator from "../components/detail_view/Separator.js"

import '../assets/css/components/TaskList.css';
import I18n from "i18n-js";


const getDateSections = (list, att) => {
    const now = new Date()
    const dateObjects = list.map((item) => new Date(item.attributes[att]))

    let mañana = new Date(now.getFullYear(),now.getMonth(),now.getDate()+1)
    const hoy      = list.filter((i) => new Date(i.attributes[att]) > now && new Date(i.attributes[att]) < mañana)
    const vencidas = list.filter((i) => new Date(i.attributes[att]) < now)

    // Create a Set to store unique day values
    const uniqueDaysSet = new Set();

    for (let i=0;i<8;i++){
        let days_one_week_from_now = new Date()
        days_one_week_from_now.setDate(i+now.getDate())
        uniqueDaysSet.add(days_one_week_from_now.getFullYear()+"-"+(days_one_week_from_now.getMonth()+1)+"-"+days_one_week_from_now.getDate());
    }

    // Iterate through the date objects and extract the day component
    for (const dateObj of dateObjects) {
        if (dateObj.toString() === "Invalid Date") continue;
        const day = dateObj.getFullYear()+"-"+(dateObj.getMonth()+1)+"-"+dateObj.getDate()
        uniqueDaysSet.add(day);
    }
    var uniqueDaysArray = Array.from(uniqueDaysSet);
    uniqueDaysArray = uniqueDaysArray.filter((i) => i!=="Invalid Date").map((i)=>new Date(i))

    const result = [];
    for (let item in uniqueDaysArray){
        if (uniqueDaysArray[item].valueOf()<now.valueOf()) continue;
        let sect = list.filter((i) =>{
            let date = new Date(i.attributes[att]);
            return date.toLocaleDateString() === uniqueDaysArray[item].toLocaleDateString() && date.valueOf()>now.valueOf()
            })
        result.push({date:uniqueDaysArray[item], list:sect});
    }


    result.unshift({date:now, list:hoy})
    if (vencidas.length>0) result.unshift({date:new Date(0), list:vencidas})

    result.sort((i) => -i.date)

    // mostrar solo los proximos 7 dias
    let a = result.filter((i)=>i.date.valueOf()<now.valueOf()+86400000*7)
    return a;
}

const SectionHeader = (props) => {
    var { day } = props;
    day = new Date(day);
    const now = new Date()
    const tomorrow = new Date(Date.now()+86400000)

    var title = day.getDate() + " " + I18n.t("agendaListOf") + " " + I18n.t("Month"+(1+day.getMonth())); // translate

    if (day.toLocaleDateString()===now.toLocaleDateString()){
        title = I18n.t("agendaListToday"); // translate
    }
    else if(day.toDateString()===tomorrow.toDateString()){
        title = I18n.t("agendaListTomorrow")+", " + day.getDate() + " " + I18n.t("agendaListOf") + " " + I18n.t("Month"+(1+day.getMonth())); // translate
    }
    else if(day.valueOf()<now.valueOf()){
        title = I18n.t("agendaListOverdue"); // translate
    }

    return (
        <li className='call-list-section-title'>
            <Separator className="call-list-section-footer-separator"/>
            <p className="call-list-section-title-text"> {title} </p>
        </li>
    )
}

const SectionFooter = (props) => {
    var { day } = props;
    day = new Date(day);
    const now = new Date()
    if(day.valueOf()<now.valueOf() && day.toDateString()!== now.toDateString()){
        return
    }
    return (
        <li className='call-list-section-footer'>
            <Link className='add-task-list-link' to={"/task?date="+day.getFullYear()+"-"+(day.getMonth()+1)+"-"+day.getDate()}>+</Link>
        </li>
    )
}

const List = (props) => {

    const { keyExtractor, data, itemMapper, sections} = props;

    if (sections) {
        const sectionList = getDateSections(data, "date_start");
        for (let item in sectionList){console.log(sectionList[item])}
        return (
            <div className="call-list-container">
                {sectionList.map((items) =>
                    <ul className="call-list">

                        <SectionHeader day={items.date} />

                        {items.list.map((item)=>
                            <li key={keyExtractor(item)}>
                                {itemMapper(item)}
                            </li>
                        )}

                        <SectionFooter day={items.date} />

                    </ul>
                )}
            </div>
        )
    }

    const items = data.map((item) =>
        <li key={keyExtractor(item)}>
            {itemMapper(item)}
        </li>
        )

    return (
        <div className="call-list-container">
            <ul className="call-list">
                {items}
            </ul>
        </div>
    )
};

export default List;
