import { useLocation } from "react-router-dom";

import List from "../components/List";
import { PagedSearch } from "../services/Api";
import { useState, useEffect, useCallback } from "react";
import Input from "../components/detail_view/Input.js"

import { Spinner } from "react-activity";
import LeadCard from '../components/LeadCard';
import i18n  from "i18n-js";

import "../assets/css/pages/Search.css";
import "react-activity/dist/library.css";


const Search = () => {
  const [leadList, setLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [search, setSearch] = useState("");

  const location = useLocation();
  const module = location.pathname.split("/").pop();
  const moduleCapitalized = module.charAt(0).toUpperCase() + module.slice(1);
  const moduleSingular = module.slice(0, -1);

  const searchObject = new PagedSearch(10, moduleCapitalized)

  //const { pageSize } = props;

  const doSearch = async (_search) => {
    setLoading(true)
    searchObject.newSearch(_search);

    let data = await searchObject.next();

    setLeadList(data);

    setLoading(false);
  }
  const loadPage = async () => {
    const data = await searchObject.next();
    setLeadList([...leadList, ...data]);
  }
  const handleSearchChange = ({ target }) => {
    const _search = target.value;
    if (_search == ""){
      setLeadList([])
      setSearch(_search);
      return;
    }
    doSearch(_search);
    setSearch(_search);
  };

  const emptyComponent = () => {
    return (
      (!loading&&<p className="emptyList"> No se encontro a "{search /* TRADUCIR */}" </p>)||
      <Spinner className="spinner" size={32} speed={1} animating={loading} />
    )
  }

  return (
    <div>
    <div className="search-header-container">
      <h1>{i18n.t("searchTitle")}</h1><br/>
        <Input
          className="search-header-search-input"
          inputclassname="search-header-search-input-component"
          placeholder={i18n.t('searchInput'+ moduleCapitalized)}
          autoFocus={true}
          onChange={handleSearchChange}
          />
    </div>
    <div className="search-list-container">

      { leadList.length === 0 ? <div className="search-empty-component">{emptyComponent()}</div> : <div >
      <List
        key="search"
        data={leadList}
        keyExtractor={(item) => item.id}
        itemMapper={(item)=> <LeadCard key={item.id} lead={item} navigateTo={"/"+moduleSingular+"/"+item.id+(moduleSingular==="lead"?"/detail":"")}/>}
      />
        <div className="search-empty-component">
          {nextPage!==0&&<Spinner className="spinner" size={32} speed={1} animating={loading} />}
        </div>
      </div>
      }

    </div>
    </div>
  );
};

export default Search;
