import '../../assets/css/components/detail_view/Title.css';


const Title = (props) => {
    const {status, value, onChange, name, onFocus} = props;
    return (
      <div className="lead-title-container">

      <div className="lead-title-text">

          <input
            className='lead-title-input'
            name={name}
            placeholder={""}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
          />

        <div className="lead-title-buttons">
            {/*phoneButton()*/}
            {/*whatsappButton()*/}
        </div>
      </div>

      <p className="lead-title-status">{status}</p>

    </div>
    );
  }

export default Title;