import List from "../components/TaskList.js";
import { getList, isAuth } from "../services/Api";
import { useState, useEffect, useCallback } from "react";
import { Spinner } from "react-activity";
import TaskCard from '../components/TaskCard';

import "../assets/css/pages/Tasks.css";
import "react-activity/dist/library.css";

const Tasks = () => {

  const [leadList, setLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  // Metodo para crear la lista de elementos haciendo el request al CRM
  const createLeadList = useCallback (async () => {

    if (loading) return
    setLoading(true)

    // si esta autenticado y aun quedan paginas
    if (await isAuth() && nextPage !== 0){

      // hago el request
      await getList(
        "Calls",
        1000,
        nextPage,
        "Planned",
        null,
        null,
        false,
        null,
        "date_start"
        )

      // si el request es exitoso
      .then((req) => {

        // agrego los nuevos elementos a la lista
        setLeadList([...leadList, ...req.data])

        // se pasa a la proxima pagina
        setNextPage(nextPage+1)

        // Si la data esta vacia y no hay links a la proxima pagina (es decir la anterior fue la ultima pagina y esta esta vacia)
        if (req.data.length===0 || !Object.keys(req).includes("links")){
          // la proxima pagina es 0, asi indico que no hay mas
          setNextPage(0)
        }
      }).catch()
    }
    // indico que no se esta cargando
    setLoading(false)

  },[nextPage,leadList,loading]);

  useEffect(() => {
    createLeadList()
  }, [createLeadList])

  /*/
  if (leadList.length === 0){
    return (
      (!loading&&<p>no items</p>)||
    )
  }//*/

  return (
    <div className="lead-list-container">

      <List
        sections={true}
        data={leadList}
        keyExtractor={(item) => item.id}
        itemMapper={(item)=> <TaskCard task={item} navigateTo={"/lead/"+item.attributes.parent_id}/>}
      />

    </div>
  );
};

  
export default Tasks;
